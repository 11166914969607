body{
    background: #F6F8FA;
}

.logo{
    width: 100px;
}

.home-title{
    color: #293C5B;
    font-size: 24px;
    font-weight: 600;
}

.content-section{
  padding: 40px;
}

@media (max-width: 339.98px) {
    .content-section{
        padding: 20px;
    }
    .logo{
        width: 80px;
    }
    .home-title{
        font-size: 16px;
    }
}

@media (min-width:340px) and (max-width: 360.98px) {
    .content-section{
        padding: 20px;
    }
    .logo{
        width: 80px;
    }
    .home-title{
        font-size: 18px;
    }
}

@media (min-width: 361px) and (max-width: 575.98px) {

    .content-section{
        padding: 20px;
    }
    .logo{
        width: 80px;
    }
    .home-title{
        font-size: 18px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .content-section{
        padding: 20px;
    }
    .logo{
        width: 80px;
    }
    .home-title{
        font-size: 20px;
    }
}

