.calculator-range-label{
    color: #293C5B;
    font-size: 16px;
    font-weight: 400;
}

.calculator-range::-webkit-slider-thumb {
    background: #1473E6;
}

.calculator-range::-moz-range-thumb {
    background: #1473E6;
}

.calculator-range::-ms-thumb {
    background: #1473E6;
}

.calculator-emi-number{
    color: #293C5B;
    font-size: 24px;
    font-weight: 600;
}

.calculator-emi-title{
    color: #293C5B;
    font-size: 16px;
    font-weight: 400;
}

.calculator-summary-title{
    color: #293C5B;
    font-size: 15px;
    font-weight: 400;
}

.calculator-summary-div{
    margin:0 ;
    padding: 20px 50px 20px 50px ;
}





